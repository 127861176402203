import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
`;

const StyledH1 = styled.h1`
  padding: 1rem;
  font-size: 2rem;
`;

const TwitterSection = styled.div``;

const Twitter = styled.div`
  width: 500px;
  padding: 1rem 1rem 0rem 1rem;
`;

const YoutubeSection = styled.div`
  padding: 1rem;
`;

const Youtube = styled.div`
  padding: 1rem 1rem 1rem 1rem;
`;

const SNSPage = ({ data }) => {
  const allTweets = data.allTwitterJson.nodes;
  const allVideos = data.allYoutubeJson.nodes;

  return (
    <Layout title="SNS">
      <SEO pagetitle="SNS" pagedesc="SNS について" />
      <ContentWrapper>
        <Main>
          <TwitterSection>
            {allTweets.map((tweet) => (
              <Twitter>
                <blockquote
                  class="twitter-tweet"
                  data-lang="ja"
                  data-theme="dark"
                >
                  <a
                    href={`https://twitter.com/yukihirarinta/status/${tweet.tweet_id}?ref_src=twsrc%5Etfw`}
                  ></a>
                </blockquote>
              </Twitter>
            ))}
          </TwitterSection>
          <YoutubeSection>
            {allVideos.map((video) => (
              <Youtube>
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.video_id}`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Youtube>
            ))}
          </YoutubeSection>
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allTwitterJson {
      nodes {
        author_id
        created_at
        tweet_id
      }
    }
    allYoutubeJson {
      nodes {
        channel_id
        video_id
      }
    }
  }
`;

export default SNSPage;
